import React from "react";
import './Wind.css';

export const Wind = (props) => {
  const speed = props.value ? (props.value.speed * 3.6).toFixed() : null;

  const deg = props.value ? props.value.deg : null;

  let direction = "";

  if ((deg >= 0 && deg <= 23) || (deg >= 337 && deg <= 360)) {
    direction = "N";
  } else if (deg >= 24 && deg <= 68) {
    direction = "NE";
  } else if (deg >= 69 && deg <= 113) {
    direction = "E";
  } else if (deg >= 114 && deg <= 158) {
    direction = "SE";
  } else if (deg >= 159 && deg <= 203) {
    direction = "S";
  } else if (deg >= 204 && deg <= 248) {
    direction = "SW";
  } else if (deg >= 249 && deg <= 293) {
    direction = "W";
  } else if (deg >= 294 && deg <= 336) {
    direction = "NW";
  }
  return (
    <div className="wind">
      <p>Wind</p>
      <span className="iconify iconify_wind" data-icon="bx:bx-wind"/>
      <p>{direction}</p>
      <p>{speed} km/h</p>
    </div>
  );
};