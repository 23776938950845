import React from "react";
import './Humidity.css';

export const Humidity = (props) => {
  const humidity = props.value ? props.value.humidity : null;
  return (
    <div className="humidity">
      <span className="iconify" data-icon="carbon:humidity"/>
      <p>Humidity</p>
      <p>{humidity} %</p>
    </div>
  );
};