import React from "react";
import "./Describe.css";

export const Describe = (props) => {
  const description = props.value
    ? props.value[0].description[0].toUpperCase() +
      props.value[0].description.substring(1)
    : null;
  return <h3 className="weather_describe">{description}</h3>;
};
