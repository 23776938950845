import React from "react";
import './Pressure.css';

export const Pressure = (props) => {
  const pressure = props.value ? props.value.pressure : null;
  return (
    <div className="pressure">
      <p>Atmospheric pressure</p>
      <span>{pressure} hPa</span>
    </div>
  );
};