import React from "react";
import "./FeelsLike.css";

export const FeelsLike = (props) => {
  let temp = props.value ? (props.value.feels_like - 275).toFixed() : null;
  if (temp === "-0") {
    temp = temp.substring(1);
  }
  return (
    <div className="sensed_temperature">
      <p>Feels like</p>
      <span>{temp} °C</span>
    </div>
  );
};
