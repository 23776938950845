import React from "react";
import '../common/styles/Max&MinTemp.css';

export const MinTemp = (props) => {
  const temp = props.value ? (props.value.temp_min - 275).toFixed() : null;
  return (
    <div className="min_temperature">
      <h1 className="min_temperature_element">{temp}°C</h1>
      <p className="min_temperature_element">Minimal</p>
    </div>
  );
};