import axios from "axios";
import React, { useEffect, useState } from "react";
import { changeBackground } from "../../utils/changeBackground";

import { Visibility } from "../Visibility/Visibility";
import { FeelsLike } from "../FeelsLike/FeelsLike";
import { Pressure } from "../Pressure/Pressure";
import { Wind } from "../Wind/Wind";
import { SunriseSunset } from "../Sunrise&Sunset/SunriseSunset";
import { Humidity } from "../Humidity/Humidity";
import { MinTemp } from "../MinTemp/MinTemp";
import { Describe } from "../Describe/Describe";
import { Temp } from "../Temp/Temp";
import { MaxTemp } from "../MaxTemp/MaxTemp";

import "./AppView.css";

export const AppView = () => {
  const [data, setData] = useState({});
  const [location, setLocation] = useState("");

  const url = `https://api.openweathermap.org/data/2.5/weather?q=${location ? location : 'warsaw'}&lang=en&appid=${process.env.REACT_APP_APPID}`;

  useEffect(() => {
    if (Object.keys(data).length === 0) {
      axios.get(url).then((response) => {
        setData(response.data);
        changeBackground(response.data);
      });
    }
  }, []);

  const searchLocation = (e) => {
    if (e.key === "Enter") {
      axios.get(url).then((response) => {
        setData(response.data);
        changeBackground(response.data);
      });
      setLocation("");
    }
  };

  return (
    <div className="container">
      <section className="first_section">
        <input
          type="text"
          placeholder="Wpisz nazwę miasta"
          value={location}
          onChange={(e) => setLocation(e.target.value)}
          onKeyPress={searchLocation}
        />
        <h2 className="city_name">{data.name}</h2>
        <Temp value={data.main} />
        <Describe value={data.weather} />
        <MaxTemp value={data.main} />
        <MinTemp value={data.main} />
      </section>
      <section className="second_section">
        <SunriseSunset value={data.sys} timezone={data.timezone} />
        <Humidity value={data.main} />
        <Wind value={data.wind} />
        <Pressure value={data.main} />
        <FeelsLike value={data.main} />
        <Visibility value={data.visibility} />
      </section>
    </div>
  );
};
