export const weatherID = [
  {
    index: 0,
    id: [800],
    day: true,
    main: "Clear",
    nameClass: "day_clear",
  },
  {
    index: 1,
    id: [800],
    day: false,
    main: "Clear",
    nameClass: "night_clear",
  },
  {
    index: 2,
    id: [801, 802],
    day: true,
    main: "Clouds",
    nameClass: "day_cloudy",
  },
  {
    index: 3,
    id: [801, 802],
    day: false,
    main: "Clouds",
    nameClass: "night_cloudy",
  },
  {
    index: 4,
    id: [741],
    day: true,
    main: "Fog",
    nameClass: "day_fog",
  },
  {
    index: 5,
    id: [741],
    day: false,
    main: "Fog",
    nameClass: "night_fog",
  },
  {
    index: 6,
    id: [
      500, 501, 502, 503, 504, 511, 520, 521, 522, 531, 300, 301, 302, 310, 311,
      312, 313, 314, 321,
    ],
    day: true,
    main: "Rain",
    nameClass: "day_rain",
  },
  {
    index: 7,
    id: [
      500, 501, 502, 503, 504, 511, 520, 521, 522, 531, 300, 301, 302, 310, 311,
      312, 313, 314, 321,
    ],
    day: false,
    main: "Rain",
    nameClass: "night_rain",
  },
  {
    index: 8,
    id: [600, 601, 602, 611, 612, 613, 615, 616, 620, 621, 622],
    day: true,
    main: "Snow",
    nameClass: "day_snowy",
  },
  {
    index: 9,
    id: [600, 601, 602, 611, 612, 613, 615, 616, 620, 621, 622],
    day: false,
    main: "Snow",
    nameClass: "night_snowy",
  },
  {
    index: 10,
    id: [200, 201, 202, 210, 211, 212, 221, 230, 231, 232],
    day: true,
    main: "Storm",
    nameClass: "day_storm",
  },
  {
    index: 11,
    id: [200, 201, 202, 210, 211, 212, 221, 230, 231, 232],
    day: false,
    main: "Storm",
    nameClass: "night_storm",
  },
  {
    index: 12,
    id: [803, 804],
    day: true,
    main: "Clouds",
    nameClass: "day_very_cloudy",
  },
  {
    index: 13,
    id: [803, 804],
    day: false,
    main: "Clouds",
    nameClass: "night_very_cloudy",
  },
];
