import {weatherID} from "./weatherID";

export const changeBackground = (data) => {
  const timezone = data.timezone / 3600;
  const date = new Date();

  const currentHours = new Date(
    date.setHours(date.getUTCHours() + timezone)
  ).getHours();
  const currentMinutes = new Date().getUTCMinutes();

  const sunriseUnix = data.sys.sunrise;
  const sunsetUnix = data.sys.sunset;

  const sunrise = new Date(sunriseUnix * 1000).getHours();
  const sunriseHours = new Date(
    date.setHours(sunrise + timezone)
  ).getUTCHours();
  const sunriseMinutes = new Date(sunriseUnix * 1000).getUTCMinutes();

  const sunset = new Date(sunsetUnix * 1000).getHours();
  const sunsetHours = new Date(date.setHours(sunset + timezone)).getUTCHours();
  const sunsetMinutes = new Date(sunsetUnix * 1000).getUTCMinutes();

  let day;

  if (currentHours >= sunriseHours && currentHours <= sunsetHours) {
    if (currentHours > sunriseHours && currentHours < sunsetHours) {
      day = true;
    } else if (
      currentHours >= sunriseHours &&
      currentMinutes >= sunriseMinutes
    ) {
      if (currentHours <= sunsetHours && currentMinutes < sunsetMinutes) {
        day = true;
      } else {
        day = false;
      }
    } else {
      day = false;
    }
  } else {
    day = false;
  }

  if (day) {
    const indexes = weatherID.filter((e) => e.day === true);
    changeClass(indexes);
  } else {
    const indexes = weatherID.filter((e) => e.day === false);
    changeClass(indexes);
  }

  function changeClass(indexes) {
    const weatherId = data.weather[0].id;
    const indexesIdx = indexes.findIndex((e) => e.id.includes(weatherId), [0]);
    const index = indexes[indexesIdx].index;
    const app = document.querySelector(".container");
    const list = app.classList;
    app.classList.remove(...list);
    app.classList.add(`container`);
    app.classList.add(`${weatherID[index].nameClass}`);
  }
};
