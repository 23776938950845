import React from "react";
import './SunriseSunset.css';

export const SunriseSunset = (props) => {
  const timezone = props.timezone / 3600;

  const sunriseUnix = props.value ? props.value.sunrise : null;
  const sunsetUnix = props.value ? props.value.sunset : null;

  const sunrise = new Date(sunriseUnix * 1000).getHours();
  const sunset = new Date(sunsetUnix * 1000).getHours();
  const sunriseMin = new Date(sunriseUnix * 1000).getUTCMinutes();
  const sunsetMin = new Date(sunsetUnix * 1000).getUTCMinutes();

  const date = new Date();
  const sunriseHour = new Date(date.setHours(sunrise + timezone)).getUTCHours();
  const sunsetHour = new Date(date.setHours(sunset + timezone)).getUTCHours();

  const sunriseTime = `${sunriseHour}:${
    sunriseMin < 10 ? "0" + sunriseMin : sunriseMin
  }`;
  const sunsetTime = `${sunsetHour}:${
    sunsetMin < 10 ? "0" + sunsetMin : sunsetMin
  }`;

  return (
    <div className="sunrise_sunset">
      <div className="sunrise">
        <span className="iconify" data-icon="bi:sunrise"/>
        <p>Sunrise</p>
        <p>{sunriseTime}</p>
      </div>
      <div className="sunset">
        <span className="iconify" data-icon="bi:sunset"/>
        <p>Sunset</p>
        <p>{sunsetTime}</p>
      </div>
    </div>
  );
};