import React from "react";
import './Visibility.css';

export const Visibility = (props) => {
  const visibility = props.value ? props.value / 1000 : null;
  return (
    <div className="visibility_on_the_road">
      <p>Visibility</p>
      <span>{visibility} km</span>
    </div>
  );
};